import React, {useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Modal, Form, Button} from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEye, faExclamationTriangle, faTools } from '@fortawesome/free-solid-svg-icons'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope} from '@fortawesome/free-solid-svg-icons'
import InputGroup from 'react-bootstrap/InputGroup'
import { Link } from "react-router-dom";

import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'






function MyVerticallyCenteredModal(props) {


    const [passwordShown1, setPasswordShown1] = useState(false);
    const [passwordShown2, setPasswordShown2] = useState(false);
    const [phoneivalue, setPhoneiValue] = useState();
  
    const formik = useFormik({
      initialValues: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        pwordnew: '',
        pwordconf: '',
      },
      
      validationSchema: Yup.object({
        firstName: Yup.string()
          .max(25, 'Must be 25 characters or less')
          .required('Required'),
        lastName: Yup.string()
          .max(25, 'Must be 25 characters or less')
          .required('Required'),
        phone: Yup.string()
          .max(25, 'Must be 25 characters or less')
          .required('Required'),
        email: Yup.string()
          .email('Invalid email address')
          .required('Required'),
        pwordnew: Yup.string().required('Password is required'),
        pwordconf: Yup.string()
             .oneOf([Yup.ref('pwordnew'), null], 'Passwords must match')
      }),
  
      onSubmit: values => {
  
        console.log(JSON.stringify(values, null, 2))
  
       fetch('https://clubcamayan.com/rest/proja/api/saveprereg', {
          method: 'post',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(values, null, 2)
  
        }).then(res=> res.json())
          .then(res => console.log(res));
        
        props.onHide();
        props.onNext();
        //setModalShowty(true);
  
      },
  
  
    });
  
    function closeThisModal() {
      
  
    }
    const togglePasswordVisiblity = () => {
      console.log('toggle eye')
      setPasswordShown1(passwordShown1 ? false : true);

    };
    const togglePasswordVisiblity2 = () => {
        console.log('toggle eye')
        setPasswordShown2(passwordShown2 ? false : true);

    };
  
  
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton  style={{backgroundColor: '#e9ecef'}}>
          <Modal.Title id="contained-modal-title-vcenter">
            Create A Free Account &nbsp;&nbsp;<span style={{fontSize: '14px'}}></span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
        <Container>
          <Row>
            <Col sm={6} md={6}>
  
              <Form onSubmit={formik.handleSubmit} id="regform">
                  <Form.Row>
                    <Form.Group as={Col} controlId="uregFname">
                      <Form.Label htmlFor="firstName">First Name{formik.touched.firstName && formik.errors.firstName ? (<span style={{fontSize: '11px', color: 'red'}}>{formik.errors.firstName}</span>) : null}</Form.Label>
                      <Form.Control id="firstName" type="text" {...formik.getFieldProps('firstName')} size='sm'/>
                      {/* <label htmlFor="firstName">First Name</label>
                      <input id="firstName" type="text" {...formik.getFieldProps('firstName')} /> */}
                      
                    </Form.Group>
  
                    <Form.Group as={Col} controlId="uregLname">
                      <Form.Label htmlFor="lastName">Last Name{formik.touched.lastName && formik.errors.lastName ? (<span style={{fontSize: '11px', color: 'red'}}>{formik.errors.lastName}</span>) : null}</Form.Label>
                      <Form.Control id="lastName" type="text" {...formik.getFieldProps('lastName')} size='sm' />
                      
                      
                    </Form.Group>
                  </Form.Row>
  
                  <Form.Group controlId="uregPhone">
                    <Form.Label htmlFor="phone">Phone{formik.touched.phone && formik.errors.phone ? (<span style={{fontSize: '11px', color: 'red'}}>{formik.errors.phone}</span>) : null} </Form.Label>
                    <InputGroup size='sm'>
                     <InputGroup.Prepend><InputGroup.Text><FontAwesomeIcon icon={faPhone} /></InputGroup.Text></InputGroup.Prepend>
                      <Form.Control id="phone" type="text" {...formik.getFieldProps('phone')} size='sm' />

                    {/* <PhoneInput
                          placeholder="Enter phone number"
                          value={phoneivalue}
                          onChange={setPhoneiValue}
                    /> */}




                    </InputGroup>
                      
                  </Form.Group>
  
                  <Form.Group controlId="uregEmail">
                    <Form.Label htmlFor="email">Email Address{formik.touched.email && formik.errors.email ? (<span style={{fontSize: '11px', color: 'red'}}>{formik.errors.email}</span>) : null}</Form.Label>
                    <InputGroup size='sm'>
                      <InputGroup.Prepend><InputGroup.Text><FontAwesomeIcon icon={faEnvelope} /></InputGroup.Text></InputGroup.Prepend>
                      <Form.Control id="email" type="email" {...formik.getFieldProps('email')} size='sm' />
                    </InputGroup>
                    
                  </Form.Group>


                  <Form.Group controlId="pass1">
                          <Form.Label htmlFor="pass1">New Password{formik.touched.pwordnew && formik.errors.pwordnew ? (<span style={{fontSize: '12px', color: 'red', paddingLeft: '20px'}}>{formik.errors.pwordnew}</span>) : null}</Form.Label>
                          <InputGroup size='sm'>
                              <Form.Control id="pwordnew" type={passwordShown1 ? "text" : "password"} {...formik.getFieldProps('pwordnew')} size='sm' />
                          <InputGroup.Append><InputGroup.Text><FontAwesomeIcon icon={faEye} onClick={togglePasswordVisiblity} /></InputGroup.Text></InputGroup.Append>
                          
                          </InputGroup>
                  </Form.Group>
                  <Form.Group controlId="pass2">
                          <Form.Label htmlFor="pass2">Confirm Password{formik.touched.pwordconf && formik.errors.pwordconf ? (<span style={{fontSize: '12px', color: 'red', paddingLeft: '20px'}}>{formik.errors.pwordconf}</span>) : null}</Form.Label>
                          <InputGroup size='sm'>
                              <Form.Control id="pwordconf" type={passwordShown2 ? "text" : "password"} {...formik.getFieldProps('pwordconf')} size='sm' />
                          <InputGroup.Append><InputGroup.Text><FontAwesomeIcon icon={faEye} onClick={togglePasswordVisiblity2} /></InputGroup.Text></InputGroup.Append>
                          
                          </InputGroup>
                  </Form.Group>
  
                  <Button variant="primary" type="submit" style={{width: '100%', marginBottom: '0px'}}>Create Free Account</Button>
  
              </Form>

              <span style={{fontSize: '12px'}}>By becoming a member you agree to our <Link to={`/terms`}>Terms & Conditions.</Link> </span>
  
            </Col>
  
  
            <Col sm={6} md={6} style={{padding: '40px'}}>
  
            <b>Benefits of being a member:</b> <br /><br />
            <ul style={{listStyle: 'none', fontSize: '13px'}}>
              <li><FontAwesomeIcon icon={faCheck} style={{color: 'green'}} />&nbsp;&nbsp;P300 voucher that can be applied to any purchase of merchandise items in any of our gift shops</li>
              <li><FontAwesomeIcon icon={faCheck} style={{color: 'green'}} />&nbsp;&nbsp;Add Items to your Watch List using MYDEALS</li>
              <li><FontAwesomeIcon icon={faCheck} style={{color: 'green'}} />&nbsp;&nbsp;View recently sold deals with price data</li>
              <li><FontAwesomeIcon icon={faCheck} style={{color: 'green'}} />&nbsp;&nbsp;Make purchases at rock bottom prices!</li>
            </ul>
 
            <span style={{fontSize: '13px', fontWeight:'bold'}}> </span>
            
            </Col>
          </Row>
        </Container>
  
        </Modal.Body>
        {/* <Modal.Footer>
          
        </Modal.Footer> */}
      </Modal>
    );
  }

  export default MyVerticallyCenteredModal;