import React, {useState, useEffect} from 'react'

import Table from 'react-bootstrap/Table'
import Countdown,  { CountdownRenderProps } from 'react-countdown';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTrashAlt} from '@fortawesome/free-regular-svg-icons'
import {faCheck} from '@fortawesome/free-solid-svg-icons'

const remfwl = (islogged,eqpn) => {
    fetch(`https://clubcamayan.com/rest/proja/api/remfwl?uid=${islogged}&enum=${eqpn}`)
    .then(res => res.json())
    .then(
    (result) => {
        // console.log('asdfasdfasdf',result)
        // document.getElementById(thefa).style.display = "none"
        // document.getElementById(theofa).style.display = "block"

    },
    (error) => {}
    )
}
var sa= 0
var bp = 0
var tot = 0

function UserPurchases(props) {

    console.log(props.uid)
    const [soldlst, setsoldlst] = useState([])

    useEffect(() => {
        fetch(`https://clubcamayan.com/rest/proja/api/getsolduser?olaid=DAX1&activ=1&uid=${props.uid}`)
        .then(res => res.json())
        .then(
          (result) => {
            console.log(result)
            setsoldlst(result)
          },
          (error) => {}
        )
        window.scrollTo(0, 0)
    }, [window.location.href])
    const cdrender = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
          // Render a completed state
          //return <Completionist />;
        }
        // Render a countdown
        return (
          <span>
            {days}d {hours}h {minutes}m {seconds}s
          </span>
        );
    };
   


    return (
        <div style={{backgroundColor: 'white', borderLeft: '1px solid #dedede', borderRight: '1px solid #dedede', borderBottom: '1px solid #dedede', padding: '10px'}}>
    
            

            {props.uid>0?<>

                {soldlst.length>0?<>
                <Table striped bordered size="sm" style={{fontSize: '12px', border: '0px solid #dedede'}}>
                    <thead>
                        <tr>
                          <th>LOT</th>
                          <th></th>
                          <th>Item Description/Title</th>
                          <th>Winning Bid</th>
                          <th>Buyer Premium</th>
                          <th>Total Amount</th>
                          <th>Sold When</th>
                        </tr>
                    </thead>
                    <tbody>

                    {soldlst.map( (ti,index) => (    
                        <tr id={`wlr${ti.equipnum}`}>
                            <td>
                            {(() => {
                                 // console.log(ti.HIBIDDER,' | ',islogged)
                            })()}
                            {ti.catalogno}
                            </td>
                            <td><Link to={`/forsale/${ti.equipnum}`} key={ti.equipnum}><img src={`https://bidlive.bid/photos/olaitems/${ti.imagelg}?${new Date().getTime()}`} width='90px' /></Link></td>
                            <td><Link to={`/forsale/${ti.equipnum}`} key={ti.equipnum}>{ti.sdesc}</Link></td>
                            <td>{ti.soldamount}
                            
                            
                            
                            </td>
                            <td>{ti.bpamntpaid}
                            
                            
                            </td>
                            <td>
                                {(() => {
                                 // console.log(ti.HIBIDDER,' | ',islogged)
                                 var sa = parseInt(ti.soldamount)
                                 var bp = parseInt(ti.bpamntpaid)
                                 var tot = sa + bp
                                 return tot
                                })()}
                            </td>
                            <td>
                                {(() => { 
                                    const months = ["Jan", "Feb", "Mar","Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                                    let current_datetime = new Date(ti.enddate)
                                    let formatted_date = months[current_datetime.getMonth()]+" "+current_datetime.getDate() + ", " + current_datetime.getFullYear()
                                    //console.log(formatted_date)
                                    return formatted_date;
                                })()}        
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                </>:<>
                    <br/><br/><br/><br/>
                    <div style={{width: '100%', textAlign: 'center'}}>You have not purchased any items yet.</div>
                
                </>}
            </>:<>
                <br/><br/><br/><br/>
                <div style={{width: '100%', textAlign: 'center'}}>You dont have any active items at the moment</div>
                
            </>}

            <br /><br /><br /><br/>
        </div>
    )
}

export default UserPurchases
