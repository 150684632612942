import React, {useState, useEffect} from 'react'

import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";

import Navtop from './Navtop.js'

import Form from 'react-bootstrap/Form'
import Itemcard from './Itemcard.js'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import CurrencyFormat from 'react-currency-format';
import CardDeck from 'react-bootstrap/CardDeck'
import CardGroup from 'react-bootstrap/CardDeck'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faInfoCircle, faStar, faAward } from '@fortawesome/free-solid-svg-icons'

import Card from 'react-bootstrap/Card'
import {FormControl, Button, CardColumns, Modal } from 'react-bootstrap'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import InputGroup from 'react-bootstrap/InputGroup'

import noimgcar from '../assets/img/img-placeholder.jpg';
import noimgbike from '../assets/img/img-placeholder-bike.jpg';
import noimghouse from '../assets/img/img-placeholder-house.jpg';
import noimgmisc from '../assets/img/img-placeholder-misc.jpg';
import noimggeneric from '../assets/img/img-placeholder-generic.jpg';
import noimgequip from '../assets/img/img-placeholder-equip.jpg';
import noimgatv from '../assets/img/img-placeholder-atv.jpg';
import aboutFurnBackgrnd from '../assets/img/aboutFurnBackgrnd.jpg';
import fivestars from '../assets/img/5stars.png';

import FPCmiscbargdiv from './FPCmiscbar-gdiv.js'
import FPCfooter from './FPCfooter.js'
import { insert } from 'formik';



let andthese = [];
let rmvthese = [];
const onlyonce = 1;



function Categitems ({match}) {
    
    const [dspsitedesc, setDspSiteDesc] = useState("auction site...");
    const [dspicount, setDspICount] = useState(0);
    const [allinsite, setAllInSite] = useState([]);
    const [allinsiteOrig, setAllInSiteOrig] = useState([]);
    const [allcatsinsite, setAllCatsInSite] = useState([]);
    const [allmakeisite, setAllmakeisite] = useState([]);
    const [allmdlisite, setAllmdlisite] = useState([]);
    const [urcisite, seturcisite] = useState([]);    
    const [checkedItems, setCheckedItems] = useState({}); //plain object as state
    const [showTheseMakes, setShowTheseMakes] = useState([]);
    const [onlyTheseMakes, setOnlyTheseMakes] = useState([]);
    const [preloadMsg, setPreloadMsg] = useState("Loading...");

    const [yrdata, setYrData] = useState([]);
    const [prices, setPrices] = useState([]);

    const [show, setShow] = useState(match.params.aucsite=="TH1"?false:false);
    const handleClose = () => setShow(false);

    const [showTheseMdls, setShowTheseMdls] = useState([]);
    var iniml = [];
    var inimdls = [];
    
    //const categsmry = useSelector(state => state.categsmry);
    //const categnam = (categsmry.find(el => el.catid == match.params.cid));
    

    sessionStorage.setItem('aucsite', match.params.aucsite);
    
    useEffect(() => {
        fetch(`https://clubcamayan.com/rest/proja/api/getaisite?olaid=DAX1&activ=1&aucsite=${match.params.aucsite}`)
        .then(res => res.json())
        .then(
          (result) => {
            
            switch (match.params.aucsite) {
                case 'TH1': setDspSiteDesc("Angeles City, Philippines"); break;
                case 'PH1': setDspSiteDesc("Mabalacat Philippines"); break;
                case 'US1': setDspSiteDesc("Texas USA"); break;
                case 'AP1': setDspSiteDesc("Guagua, Philippines"); break;
            }
            if (result.length>0) {
                setDspICount(result.length);
                setAllInSiteOrig(result);

                if (typeof match.params.cid !== 'undefined') {
                    for (let i = 0; i < result.length; i++) {
                        if (result[i].catid.trim() === match.params.cid) {
                            andthese.unshift(result[i]);
                        }
                    }
                    setAllInSite(andthese);
                }else{
                    setAllInSite(result);
                }
            }

          },
          (error) => {}
        )

        fetch(`https://clubcamayan.com/rest/proja/api/getallcatsisite?olaid=DAX1&activ=1&aucsite=${match.params.aucsite}`)
        .then(res => res.json())
        .then(
          (result) => {
            setAllCatsInSite(result);
          },
          (error) => {}
        )
        
        fetch(`https://clubcamayan.com/rest/proja/api/geturcisite?olaid=DAX1&activ=1&aucsite=${match.params.aucsite}`)
        .then(res => res.json())
        .then(
          (result) => {
            seturcisite(result[0].ilan);
          },
          (error) => {}
        )

        fetch(`https://clubcamayan.com/rest/proja/api/getamisite?olaid=DAX1&activ=1&aucsite=${match.params.aucsite}`)
        .then(res => res.json())
        .then(
          (result) => {
            setAllmakeisite(result);
            result.map(tmic => (
                iniml = [...iniml,tmic.makeid]
            ))
            setShowTheseMakes(iniml);
            
          },
          (error) => {}
        )  

        fetch(`https://clubcamayan.com/rest/proja/api/getamdlisite?olaid=DAX1&activ=1&aucsite=${match.params.aucsite}`)
        .then(res => res.json())
        .then(
          (result) => {
            setAllmdlisite(result);
            result.map(tmic => (
                inimdls = [...inimdls,tmic.model]
            ))
            setShowTheseMdls(inimdls);
            
          },
          (error) => {}
        )

        fetch(`https://clubcamayan.com/rest/proja/api/getiyrs?olaid=DAX1&activ=1&aucsite=${match.params.aucsite}`)
        .then((res) => res.json())
        .then(
            (result) => {
            // Parse json
            let years_arr = result.map((yrObj) => Number(yrObj.year)).sort();
            // Generate ranges then update quantities
            let ranges = updateQuantities(years_arr, generateRanges(2020));
            // Remove ranges with range.qty = 0 then setState
            setYrData(ranges.filter((range) => range.qty !== 0));
            },
            (error) => {
            console.log(error);
            }
        );

        fetch(`https://clubcamayan.com/rest/proja/api/getcurrprices?olaid=DAX1&activ=1&aucsite=${match.params.aucsite}`)
            .then((res) => res.json())
            .then(
              (result) => {
                // Parse json
                const sorted = result
                  .map((prices) => Number(prices.bidstart))
                  .sort((a, b) => a - b);
                // Count occurrences
                let counts = {};
                for (var i = 0; i < sorted.length; i++) {
                  var num = sorted[i];
                  counts[num] = counts[num] ? counts[num] + 1 : 1;
                }
                // Create Ranges
                let ranges = createRanges(
                  Object.keys(counts).map((key) => Number(key)),
                  10
                );
                //  Update quantities
                ranges = updateQuantitiesPrices(counts, ranges).filter(
                  (range) => range.qty !== 0
                );
                setPrices(ranges);
                
              },
              (error) => {
                console.log(error);
              }
        );

        window.scrollTo(0, 0)           

       
       

   }, [match.params.aucsite])

    const dfilters =  document.getElementById('dfilters');
    const dmodels =  document.getElementById('dmodels');
    const dmakes =  document.getElementById('dmakes');
    

   useEffect(() => {

        // if (typeof match.params.cid === 'undefined') {
        //     console.log('catid ay wala wala wala');
        
        // }else{
        //     if (allinsite.length > 0){
        //         console.log('catid ',match.params.cid);
        //         //autoCatChecked(match.params.cid);
        //     }else{
        //         console.log('catid ',match.params.cid,'but allincat is zero');
        //     }

        //     const timer = setTimeout(() => {
        //         console.log('catid ',match.params.cid,allinsite.length);
        //     }, 5000);
        //     return () => clearTimeout(timer);
        // }
        
        if (dfilters!==null&&match.params.aucsite!=="TH1") {
            dfilters.insertBefore(dmakes,dmodels)
        }else{}
        

   }, [dfilters])

   


  /*
  generate possible date ranges [ { min: 2019, max: 2020, qty: 0}, ]
  */
 const generateRanges = (currentYear) => {
    let output = [];
    let start = currentYear - 1;
    output.push({ min: currentYear - 1, max: currentYear, qty: 0 });
    for (let i = 0, ctr = 0, gap = 2; i < 10; i++, ctr++) {
      if (ctr === 2) {
        ctr = 0;
        gap++;
      }
      if (i > 7) gap = 10;
      output.push({ min: start - gap, max: start - gap + (gap - 1), qty: 0 });
      if (i === 9) output.push({ min: 0, max: output[i + 1].min - 1, qty: 0 });
      start = start - gap;
    }
    return output;
  };

  //PRICES
  const createRanges = (prices, numOfRanges) => {
    let span = 0;
    let excess = 0;
    let output = [];
    let min = 0;
    let max = 0;
    excess = prices.length % numOfRanges;
    if (prices.length / numOfRanges >= 2) {
      span = parseInt(prices.length / numOfRanges);
    } else {
      span = 2;
    }
    for (let i = 0, ctr = 0; i < prices.length; i++) {
      if (ctr === 0) min = prices[i];
      if (ctr === span - 1) {
        max = prices[i];
        output.push({ min: min, max: max, qty: 0 });
        ctr = 0;
      } else {
        ctr++;
      }
    }
    if (excess === 1) {
      output.push({
        min: prices[prices.length - 1],
        max: prices[prices.length - 1],
        qty: 0,
      });
    } else if (excess > 1) {
      output.push({
        min: prices[prices.length - excess],
        max: prices[prices.length - 1],
        qty: 0,
      });
    }
    return output;
  };

  /*
  Updates values of ranges.qty according to contents of yearArr. 
  where:
    yearArr - array of years in int data type |    ex: [ 2001, 2002 ]
    ranges - array of range objects |    ex: [ {min: 1995, max: 2020, qty: 0 } ]
  */
  const updateQuantities = (yearArr, ranges) => {
    let output = Object.assign([], ranges);
    yearArr.forEach((year) => {
      let idx = output.length - 1;
      while (true) {
        if (idx < 0) break;
        // year within range ? push then proceed to next year : check next range
        if (year >= output[idx].min && year <= output[idx].max) {
          output[idx].qty = output[idx].qty + 1;
          break;
        }
        idx--;
      }
    });
    return output;
  };

  //PRICES
  const updateQuantitiesPrices = (counts, ranges) => {
    let output = Object.assign([], ranges);
    const price = Object.keys(counts).map((key) => Number(key));
    const quantity = Object.values(counts).map((key) => Number(key));
    for (let i = 0; i < price.length; i++) {
      for (let j = 0; j < output.length; j++) {
        if (price[i] >= output[j].min && price[i] <= output[j].max) {
          output[j].qty += quantity[i];
          break;
        }
      }
    }
    return output;
  };

      
   
    
    var cursyment = "36"
    var noimg = ""

    // const autoCatChecked = cid => {
    //     console.log('these only ', cid)
    //     console.log(allinsite);

    //     for (let i = 0; i < allinsite.length; i++) {
    //         if (allinsite[i].catid === cid) {
    //             allinsite.splice(i,1); i--;
    //         }
    //     }
    //     console.log(allinsite);
    //     setAllInSite(allinsite => allinsite);
    //     setOnlyTheseMakes(onlyTheseMakes => onlyTheseMakes.concat(allinsite))
    // }

    const handleCatChecked = event => {
        if (event.target.checked) {
            for (let i = 0; i < allinsiteOrig.length; i++) {
                if (allinsiteOrig[i].catid === event.target.id) {
                    andthese.unshift(allinsiteOrig[i]);
                }
            }
            setPreloadMsg("Loading...")
            setAllInSite(andthese);
            setOnlyTheseMakes(onlyTheseMakes => onlyTheseMakes.concat(andthese))
        }else{
           for (let i = 0; i < allinsite.length; i++) {
                if (allinsite[i].catid === event.target.id) {
                    allinsite.splice(i,1); i--;
                }
            }
            setPreloadMsg("Loading...")
            setAllInSite(allinsite => allinsite);
            setOnlyTheseMakes(onlyTheseMakes => onlyTheseMakes.concat(allinsite))
        }

    }
    
    //ALL UNRESERVED ONLY
    const handleURSVChecked = event => {
        console.log(allinsiteOrig);
        if (event.target.checked) {
            console.log('remove all leave only those checked');
            for (let i = 0; i < allinsiteOrig.length; i++) {
                if (allinsiteOrig[i].reservedprice === 0) {
                    andthese.unshift(allinsiteOrig[i]);
                }
            }
            setPreloadMsg("Loading...")
            setAllInSite(andthese);
            setOnlyTheseMakes(onlyTheseMakes => onlyTheseMakes.concat(andthese))
        }else{
           console.log('put back', event.target.id);
           for (let i = 0; i < allinsite.length; i++) {
                if (allinsite[i].reservedprice === 0) {
                    allinsite.splice(i,1); i--;
                }
            }
            setPreloadMsg("Loading...")
            setAllInSite(allinsite => allinsite);
            setOnlyTheseMakes(onlyTheseMakes => onlyTheseMakes.concat(allinsite))
        }

    }
    
    //AVAILABLE MAKES FILTER ALGORITHM
    const handleChecked = event => {
        if (event.target.checked) {
            for (let i = 0; i < allinsiteOrig.length; i++) {
                if (allinsiteOrig[i].makeid === event.target.id) {
                    andthese.unshift(allinsiteOrig[i]);
                }
            }
            setPreloadMsg("Loading...")
            setAllInSite(andthese);
            setOnlyTheseMakes(onlyTheseMakes => onlyTheseMakes.concat(andthese))
        }else{
           for (let i = 0; i < allinsite.length; i++) {
                if (allinsite[i].makeid === event.target.id) {
                    allinsite.splice(i,1); i--;
                }
            }
            setAllInSite(allinsite => allinsite);
            setOnlyTheseMakes(onlyTheseMakes => onlyTheseMakes.concat(allinsite))
        }
    }

    //WILD TEXT SEARCH
    const handleWildTextSearch = event => {

        setAllInSite([]);
        andthese = [];

        var tkw = document.getElementById("thesearchkw").value;
        var regex = new RegExp( tkw, 'gi' );

        console.log(regex)

        if (tkw != '') {
            for (let i = 0; i < allinsiteOrig.length; i++) {
                console.log("res: "+allinsiteOrig[i].sdesc.match(regex))

                if (allinsiteOrig[i].sdesc.match(regex) || allinsiteOrig[i].addinfo.match(regex) || allinsiteOrig[i].addsterms.match(regex)) {
                    
                    andthese.unshift(allinsiteOrig[i]);
                }
            }
            if (andthese.length == 0) {setPreloadMsg(tkw+" Not Found.")}else{setPreloadMsg("Loading...")}
            setAllInSite(andthese);
            //console.log(andthese)
            setOnlyTheseMakes(onlyTheseMakes => onlyTheseMakes.concat(andthese))
            
        }else{
           setPreloadMsg("Nothing to search")
        }

    }
     
    
    //AVAILABLE MODELS FILTER ALGORITHM
    const handleMdlChecked = event => {
        if (event.target.checked) {
            for (let i = 0; i < allinsiteOrig.length; i++) {
                if (allinsiteOrig[i].model === event.target.id) {
                    andthese.unshift(allinsiteOrig[i]);
                }
            }
            setPreloadMsg("Loading...")
            setAllInSite(andthese);
            setOnlyTheseMakes(onlyTheseMakes => onlyTheseMakes.concat(andthese))
        }else{
           for (let i = 0; i < allinsite.length; i++) {
                if (allinsite[i].model === event.target.id) {
                    allinsite.splice(i,1); i--;
                }
            }
            setPreloadMsg("Loading...")
            setAllInSite(allinsite => allinsite);
            setOnlyTheseMakes(onlyTheseMakes => onlyTheseMakes.concat(allinsite))
        }

    }

    const handlePriceChecked = event => {
        let prcrange = event.target.id.split("-",2);
        if (event.target.checked) {
            for (let i = 0; i < allinsiteOrig.length; i++) {
                if (allinsiteOrig[i].bidstart >= prcrange[0] && allinsiteOrig[i].bidstart <= prcrange[1]) {
                    andthese.unshift(allinsiteOrig[i]);
                }
            }
            setPreloadMsg("Loading...")
            setAllInSite(andthese);
            setOnlyTheseMakes(onlyTheseMakes => onlyTheseMakes.concat(andthese))
        }else{
           for (let i = 0; i < allinsite.length; i++) {
                if (allinsiteOrig[i].bidstart >= prcrange[0] && allinsiteOrig[i].bidstart <= prcrange[1]) {
                    allinsite.splice(i,1); i--;
                }
            }
            setAllInSite(allinsite => allinsite);
            setOnlyTheseMakes(onlyTheseMakes => onlyTheseMakes.concat(allinsite))
        }
        //console.log();
    }


    const handleYrChecked = event => {
        let yrrange = event.target.id.split("-",2);
        if (event.target.checked) {
        for (let i = 0; i < allinsiteOrig.length; i++) {
                if (allinsiteOrig[i].year >= yrrange[0] && allinsiteOrig[i].year <= yrrange[1]) {
                    andthese.unshift(allinsiteOrig[i]);
                }
            }
            setAllInSite(andthese);
            setOnlyTheseMakes(onlyTheseMakes => onlyTheseMakes.concat(andthese))
        }else{
            for (let i = 0; i < allinsite.length; i++) {
                if (allinsiteOrig[i].year >= yrrange[0] && allinsiteOrig[i].year <= yrrange[1]) {
                    allinsite.splice(i,1); i--;
                }
            }
            setAllInSite(allinsite => allinsite);
            setOnlyTheseMakes(onlyTheseMakes => onlyTheseMakes.concat(allinsite))
        }
        //console.log();
    }

    const searchkw = () => {
        //fetch(`https://clubcamayan.com/rest/proja/api/remfwl?uid=${uid}&enum=${eqpn}`)
        //.then(res => res.json())
        //.then(
        //(result) => {
        //    //console.log('asdfasdfasdf',result)
        //    document.getElementById(wlrid).style.display = "none"
        //    // document.getElementById(theofa).style.display = "block"
        // },
        //(error) => {}
        //)
        
        alert(document.getElementById("thesearchkw").value);
        //console.log(allinsiteOrig);
  
      }

    //setTimeout(() => {
    //    setShow(false);
    //}, 10000)


    
    

   return(

    <Container fluid>
        
        <Navtop /> 


        <Row><Col sm={12} md={12} style={{padding: '0px', color: 'black', borderTop: '1px solid #cccccc'}}>

           <div style={{maxWidth: '1140px', border: '0px solid black'}} className="container-fluid d-flex justify-content-center" >
           
            <Container>
                <Row>
                    <Col sm={12} md={12} style={{fontSize: "14px", color: '#2e3957'}} >
                    {/* <Breadcrumb style={{marginTop: "0px"}}>
                        <Breadcrumb.Item  style={{fontSize: "14px"}}>Home</Breadcrumb.Item>
                        <Breadcrumb.Item style={{fontSize: "14px"}} active>
                            {dspcatdesc}
                        </Breadcrumb.Item>
                    </Breadcrumb> */}
                     {/* <Link to='/devdemo'>Home</Link> / {dspcatdesc} */} <br/>

                    </Col>
                </Row>
                <Row>
                <Col sm={12} md={3} style={{color: '#777777'}}>
                    <h6>Refine Results</h6>
                </Col>
                <Col sm={12} md={9} style={{color: '#555555'}}>
                    <Container>
                        <Row  style={{height:'40px', fontSize: '14px', paddingBottom:'10px', backgroundColor:'white', zIndex:'100'}}>
                        <Col sm={12} md={6} style={{color: '#555555'}}>
                            {match.params.aucsite!=='abl'?<>
                            <span style={{fontSize:'18px'}}>Available Deals&nbsp;&nbsp;&nbsp;</span>
                            <span style={{fontSize:'14px'}}>(<a href={match.params.aucsite}>Show All</a>)</span>
                            </>:null}
                        </Col>
                        <Col sm={12} md={6} style={{color: '#555555'}}>
                            <InputGroup className="mb-3" size='sm' style={{paddingTop:'0px'}}>
                            <FormControl
                                id="thesearchkw"
                                type="text"
                                placeholder="Search items for sale..."
                                aria-label="search items for sale"
                                aria-describedby="basic-addon2"
                            />
                            <InputGroup.Append size='sm'>
                            <InputGroup.Text id="basic-addon2" onClick={() => {handleWildTextSearch()}} ><FontAwesomeIcon icon={faSearch} onClick={() => {handleWildTextSearch()}} />
                            </InputGroup.Text>
                            </InputGroup.Append>
                            </InputGroup>    
                        </Col>
                        </Row>
                    </Container>
                </Col>
                </Row>
                <Row>
                    <Col sm={12} md={3} style={{border: '0px solid black'}} className="d-none d-md-block">

                    <div id='dfilters'>
                    <div style={{border: '1px solid #cccccc'}} id='dcats'>
                        <div style={{color: "#555555", backgroundColor: '#e9ecef', padding: "5px"}}>
                            <h6>Categories</h6>
                        </div>

                        <Form.Group controlId="formBasicCheckbox" style={{fontSize: '14px', paddingLeft: '10px'}}>
                        {allcatsinsite.map(tcis => (
                            <Form.Check 
                                type="checkbox" 
                                label={`${tcis.catdesc} (${tcis.ilan})`} 
                                id={tcis.catid}
                                name={tcis.catid}
                                onClick={handleCatChecked}
                                key={tcis.catid}
                            />
                        ))}
                        {match.params.aucsite!=="ID1"?<>
                        <Form.Check 
                            type="checkbox" 
                            label={`Unreserved (${urcisite})`} 
                            id='0'
                            name='unreserved'
                            onClick={handleURSVChecked}
                        />
                        </>:<>{null}</>}
                           
                        </Form.Group>

                        {/* {(() => {
                            if (typeof match.params.cid === 'undefined') {
                                console.log('catid ay wala wala wala');
                            
                            }else{
                                if (allinsite.length > 0){
                                    console.log('catid ',match.params.cid);
                                    console.log(allinsite)
                                    //autoCatChecked(match.params.cid);
                                }
                            }
                        })()} */}
                    </div>
                    

                    {/* AVAILABLE MODELS TEMPORARY TO SUBCATEGORIES, MOVED UP ONE SECTION*/}
                    <div style={{border: '1px solid #cccccc', display: match.params.aucsite=="ID1"?'none':'block'}} id='dmodels'>
                        <div style={{color: "#555555", backgroundColor: '#e9ecef', padding: "5px"}}>
                            <h6>{match.params.aucsite=="TH1"?"Item Sub-categories":"Available Models"}</h6>
                        </div>

                        <Form.Group controlId="formBasicCheckboxMdl" style={{fontSize: '14px', paddingLeft: '10px'}}>
                        {allmdlisite.map(tmic => (
                            <Form.Check 
                                type="checkbox" 
                                label={`${tmic.model} (${tmic.ilan})`} 
                                id={tmic.model}
                                name={tmic.model}
                                onClick={handleMdlChecked}
                                key={tmic.model}
                            />
                        ))}
                        </Form.Group> 
                    </div>    
                    
                    <div style={{border: '1px solid #cccccc', display: match.params.aucsite=="ID1"?'none':'block'}} id='dmakes'>
                        <div style={{color: "#555555", backgroundColor: '#e9ecef', padding: "5px"}}>
                            <h6>Available Makes</h6>
                        </div>

                        <Form.Group controlId="formBasicCheckbox" style={{fontSize: '14px', paddingLeft: '10px'}}>
                        {allmakeisite.map(tmic => (
                            <Form.Check 
                                type="checkbox" 
                                label={`${tmic.makedesc} (${tmic.ilan})`} 
                                id={tmic.makeid}
                                name={tmic.makeid}
                                onClick={handleChecked}
                                key={tmic.makeid}
                            />
                        ))}
                        </Form.Group> 
                    </div>
                    

                    </div>
                    {(() => {
                    
                        //alert(dfilters)
                        //divFilters.insertBefore(divMakes, divModels)
                        
                        //dfilters.insertBefore(dmodels, dmakes)
                    })()}
                    
                   <div style={{border: '1px solid #cccccc', display: match.params.aucsite=="ID1"?'none':'block'}} >
                        <div style={{color: "#555555", backgroundColor: '#e9ecef', padding: "5px"}}>
                            <h6>Year</h6>
                        </div>
                        <Form.Group controlId="formBasicCheckbox" style={{ fontSize: "14px", paddingLeft: "10px" }}>
                            {yrdata.map((range, idx) => (
                            

                            
                            <Form.Check
                                type="checkbox"
                                key={idx}
                                id={`${range.min}-${range.max}`}
                                label={
                                range.min === 0
                                    ? "Below " + (range.max + 1) + " (" + range.qty + ")"
                                    : range.min + "-" + range.max + " (" + range.qty + ")"
                                }
                                onClick={handleYrChecked} // range = { min: ####, max: ####, qty: # }
                            />
                            ))}
                        </Form.Group>
                        

                   </div>

                    <div style={{border: '1px solid #cccccc', display: match.params.aucsite=="TH1"?'none':'block'}} >
                        <div style={{color: "#555555", backgroundColor: '#e9ecef', padding: "5px"}}>
                            <h6>Price Range</h6>
                        </div>
                        <Form.Group
                            controlId="formBasicCheckbox"
                            style={{ fontSize: "14px", paddingLeft: "10px" }}
                        >
                            {prices.map((price, idx) => (
                            <Form.Check
                                type="checkbox"
                                key={idx}
                                id={`${price.min}-${price.max}`}
                                label={
                                price.max === price.min
                                    ? price.min + " and above " + "(" + price.qty + ")"
                                    : price.min + " - " + price.max + " (" + price.qty + ")"
                                }
                                onClick={handlePriceChecked} // price = { min: ####, max: ####, qty: # }
                            />
                            ))}
                        </Form.Group>
                        

                    </div>

                    <br />
                    {match.params.aucsite=="TH1"?<>
                    <div style={{border: '1px solid #cccccc', backgroundImage: `url(${aboutFurnBackgrnd})`}} >
                        <div style={{color: "#555555", background: 'rgba(142, 228, 175, .5)', padding: "5px", borderBottom: '1px solid #cccccc'}}>
                            <h6>About the Furniture</h6>
                        </div>
                        <div style={{color: "#000000", padding: "8px", fontSize: '12px'}}>
                        
                        
                        
                        <div style={{textAlign: 'center'}}><img src={fivestars} /></div>
                        <div style={{textAlign: 'center', textShadow: '1px 1px #ffffff'}}><b>Four Seasons Hotel & Apartments at the Venetian Macau</b></div>
                        <div style={{paddingTop: '15px'}}>
                        Fine furniture from the <b>5-Star Four Seasons Hotel & Apartments at the Venetian Macau</b>. The furniture was set up in rooms but either not used or minimally used by VIP guests for an estimated period of four years.  These items were then pulled out as part of an overhaul of the entire property and moved to Pampanga early 2019, where they are warehoused.  All items are in new to B- condition.
                        </div>
                        </div>
                    </div> <br />
                    </>:null}

                    {/* 
                    <div style={{border: '1px solid #cccccc'}} >
                        <div style={{color: "#555555", backgroundColor: '#FDFFB6', padding: "5px"}}>
                            <h6>Important Notice</h6>
                        </div>
                        <div style={{color: "#555555", padding: "8px", fontSize: '12px'}}>
                        We are testing a new bidding system and user session interface during the on-line bidding on items that are live now. Rest assured that if there are any glitches or bugs that affect bidding, the closing date and time for items will be extended while we work out any errors. Please bear with us for the next few days and help us test the bidding system.
                        </div>
                    </div>
                    */}
                    

                    </Col>
                    <Col sm={12} md={9} style={{border: '0px solid #CCCCCC'}}>
                        
                        {/* <div className="d-flex d-fill"> */}


                        <div className="container-fluid content-row">
                            <div className="row">

                                {allinsite.length==0?<>

                                    <br/><br/><span style={{color:'#cccccc'}}> {preloadMsg} </span>

                                </>:null}    

                                {allinsite.map( (ti,index) => (        
                                
                                <div className="col-sm-12 col-lg-4" style={{padding: '5px', marginRight: '0px', marginBottom: '0px'}} key={ti.equipnum}>
                                    <div className="card h-100" style={{maxWidth: '24rem'}}>
                                        {(() => {
                                            switch (ti.catid.trim()) {
                                                case 'ATV': {noimg = noimgatv;break;}
                                                case 'AUT': {noimg = noimgcar;break;}
                                                case 'PUT': {noimg = noimgcar;break;}
                                                case 'PRP': {noimg = noimghouse;break;}
                                                case 'MIS': {noimg = noimgmisc;break;}
                                                case 'MTC': {noimg = noimgbike;break;}
                                                case 'HEX': {noimg = noimgequip;break;}
                                                default: {noimg = noimggeneric}
                                            }
                                        })()}
                                        
                                        <Itemcard payload={[{...ti},noimg=`${noimg}`]}/>
                                    </div>
                                </div>
                                ))}
                            </div>

                        </div>
                    
                    </Col>

                </Row>

            </Container>

           </div>


           <div>
                                    
                
                <FPCmiscbargdiv />
                <FPCfooter />

               

           </div>

           <>
                {/* ====================================================================================================================================================
                AUCTION ITEM GENERAL MESSAGE
                ==================================================================================================================================================== */}
                <Modal show={show} size="lg" onHide={handleClose} animation={true} centered>
                <Modal.Header closeButton style={{backgroundColor:  "#8EE4AF"}}>
                    <Modal.Title>
                    <FontAwesomeIcon icon={faInfoCircle} /> &nbsp; About the Furniture
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* We are selling fine furniture from the <b>5-star Venetian Four Seasons Apartments Macau</b>. These items were used for 4 years by part time residents, and pulled out in late 2018 as part of an overhaul of the entire property.  The furniture was packaged and moved to Pampanga, where it was warehoused for 2 years.  All items are in new to B- condition. */}
                    We will be selling fine furniture from the <b>5-star Venetian Four Seasons Apartments Macau</b>. These items were used for 4 years by part time residents, and pulled out in late 2018 as part of an overhaul of the entire property.  The furniture was packaged and moved to Pampanga, where it was warehoused for 2 years.  All items are in new to B- condition.
                    <br /><br />
                    <b>Online Auction Coming Soon. To Be Announced.</b> 
                </Modal.Body>
                
                </Modal>
           </>
           

        </Col></Row>

        
        
        
    </Container>    
        
    )

}
export default Categitems;

//#D79922 <-- GOLD