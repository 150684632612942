import React, {useState, useEffect} from 'react'

import Table from 'react-bootstrap/Table'
import Countdown,  { CountdownRenderProps } from 'react-countdown';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTrashAlt} from '@fortawesome/free-regular-svg-icons'
import {faCheck, faTimes} from '@fortawesome/free-solid-svg-icons'



function UserActiveItems(props) {

    console.log(props.uid)
    const [actilst, setactilst] = useState([])

    useEffect(() => {
        fetch(`https://clubcamayan.com/rest/proja/api/getaiuser?olaid=DAX1&activ=1&uid=${props.uid}`)
        .then(res => res.json())
        .then(
          (result) => {
            console.log(result)
            setactilst(result)
          },
          (error) => {}
        )
        window.scrollTo(0, 0)
    }, [window.location.href])
    const cdrender = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
          // Render a completed state
          //return <Completionist />;
        }
        // Render a countdown
        return (
          <span>
            {days}d {hours}h {minutes}m {seconds}s
          </span>
        );
    };


    return (
        <div style={{backgroundColor: 'white', borderLeft: '1px solid #dedede', borderRight: '1px solid #dedede', borderBottom: '1px solid #dedede', padding: '10px'}}>
    
            

            {props.uid>0?<>

                {actilst.length>0?<>
                <Table striped bordered size="sm" style={{fontSize: '12px', border: '0px solid #dedede'}}>
                    <thead>
                        <tr>
                          <th>LOT</th>
                          <th></th>
                          <th>Item Description/Title</th>
                          <th>Current Bid</th>
                          <th>Highest?</th>
                          <th>Reserve Met?</th>
                          <th>Your Maxbid</th>
                          <th>Ends</th>
                        </tr>
                    </thead>
                    <tbody>

                    {actilst.map( (ti,index) => (    
                        <tr id={`wlr${ti.equipnum}`}>
                            <td>
                            {(() => {
                                 // console.log(ti.HIBIDDER,' | ',islogged)
                            })()}
                            {ti.catalogno}
                            </td>
                            <td><Link to={`/forsale/${ti.equipnum}`} key={ti.equipnum}>
                              <img src={`https://bidlive.bid/photos/olaitems/${ti.imgnames.replace(/'/g, "").split(",")[0]}?${new Date().getTime()}`} width='90px' /></Link>
                            </td>
                            <td><Link to={`/forsale/${ti.equipnum}`} key={ti.equipnum}>{ti.sdesc}</Link></td>
                            <td>{ti.HIBID}</td>
                            <td align="center">{ti.HIBIDDER==props.uid?<FontAwesomeIcon icon={faCheck} size='lg' style={{color: 'green'}}/>:null}</td>
                            <td align="center">{ti.HIBIDDER==props.uid?<>
                                {ti.HIBID<ti.reservedprice?<>
                                  <FontAwesomeIcon icon={faTimes} size='lg' style={{color: 'red'}}/>
                                </>:<>
                                  <FontAwesomeIcon icon={faCheck} size='lg' style={{color: 'green'}}/>
                                </>}
                              </>:null}
                            </td>
                            <td>{ti.MAXBID}</td>
                            <td><Countdown date={ti.enddate} renderer={cdrender} /></td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                </>:<>
                    <br/><br/><br/><br/>
                    <div style={{width: '100%', textAlign: 'center'}}>You dont have any active items at the moment</div>
                
                </>}
            </>:<>
                <br/><br/><br/><br/>
                <div style={{width: '100%', textAlign: 'center'}}>Please Sign-In again.</div>
                
            </>}

            <br /><br /><br /><br/>
        </div>
    )
}

export default UserActiveItems

