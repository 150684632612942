import React, {useState} from 'react'

import FPCmiscbar2 from './FPCmiscbar2.js'
import FPCfooter from './FPCfooter.js'

import MyVerticallyCenteredModal from './MyVerticallyCenteredModal.js'

import {Button} from 'react-bootstrap';
import Form from 'react-bootstrap/Form'

import Navtop from './Navtop.js'
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGavel } from '@fortawesome/free-solid-svg-icons'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

//import { baseUrl } from "./config";
import TermsTxt from './TermsTxt'


function Howtobuy () {

    const [modalShow, setModalShow] = React.useState(false);

    return(
    <Container fluid>
        
    <Navtop /> 


      <Row><Col sm={12} md={12} style={{padding: '0px', color: 'black',borderTop: '1px solid #cccccc'}}>

        <div style={{maxWidth: '1140px', border: '0px solid black'}} className="container-fluid d-flex justify-content-center" >
        <Container>

        {/* <Link to={`/`}><a href=''>Home</a></Link> */} 
        {/*
        <Row><Col xs={12} sm={12} md={12}>
            <a href='http://bidlive.bid'>Home</a> &nbsp;&nbsp;/&nbsp;&nbsp;  <Link to={`/aucsite/TH1`}><a href=''>Back to List</a></Link>
            &nbsp;&nbsp;/&nbsp;&nbsp;  
        </Col></Row> */}
        
        <Row><Col xs={12} sm={12} md={12}>


{/* <p style={{fontWeight: 'bold', fontSize: '16px'}}>How it works</p> */}

<p style={{fontWeight: 'bold'}}>About Us</p>

{/* <p>This system is unique, as it allows the buyers to control the schedule of the sale of these packages.  It is an auction style format, where our guests and customers are making below retail market offers for these packages, but with the “auction” ending when SBMEI decides to accept the latest offer.  There is no fixed end time for making offers.  Make an offer, be surprised!</p> */}

<p><strong>CLUBCAMAYAN</strong> is an online marketplace for our customers to buy discounted goods and services provided by Subic Bay Marine Exploratorium, Inc. (“SBMEI”).  SBMEI owns the integrated resort operating as Camayan Beach Resort, Ocean Adventure Marine Mammal Park, and Adventure Beach Water Park. CLUBCAMAYAN is the name of this marketplace and SBMEI is the seller of the packages. </p>

{/* <p>This system is unique, as it allows the buyers to control the schedule of the sale of these packages.  It is an auction style format, where our guests and customers are making below retail market offers for these packages, but with the “auction” ending when SBMEI decides to accept the latest offer.  There is no fixed end time for making offers.  Make an offer, be surprised! </p> */}


<p style={{fontWeight: 'bold'}}>How it works:</p>

<blockquote style={{paddingLeft: '50px', color: 'gray'}}>
<ul>
<li>Sign in</li>
<li>Pick from the various packages and deals, and pick a date* if it involves a visit</li>
{/* <li>Make an offer</li>
<li>Wait for notification of acceptance</li> */}
<li>Pay for it on-line, and own it!   </li>
<li>Too excited to wait for acceptance?  Then just Buy it Now at our discount off retail price.</li>
</ul>
</blockquote>

{/* <p>*Don’t forget - the further in advance you book, the better chance you have to get a lower offer accepted.</p> */}
<p>*Don’t forget - the further in advance you book, the better chance you have.</p>


{/*
<p style={{fontWeight: 'bold'}}>ONLINE AUCTIONS</p>

<p><strong>Our Timed auctions</strong> take place daily with start and end dates shown for each item.  Timed Auctions are live events, giving you immediate access to the all bidding prices.  You can place a MAXBid at any time after an item is listed in one of our timed auctions.  A MAXbid is placed by you manually in the bid box, and it is hidden unless or until other bids reach it.</p> 

<p style={{fontWeight: 'bold'}}>BUY NOW</p> 

<p>Need it now?  Our <a href='#' onClick={() => setModalShow(true)}>VIP members</a> can buy what they need immediately at competitive fixed prices.  The BUY NOW prices are only exposed to VIP Members.</p>

<p style={{fontWeight: 'bold'}}>UNRESERVED</p>

<p>Many items are sold with no reserve price.  You can search and filter by Unreserved to see these items.  The highest bid takes it!   All other items are reserved. For bids on reserved items, BIDLIVE will notify bidders if reserves have not been met 24 hours before the item closes.</p>

<p style={{fontWeight: 'bold'}}>BUYER’S PREMIUMS</p>

<p>Some items have buyers’ premiums applied, from 1% to 10% of the winning bid price. These premiums are displayed under each item’s Sales Terms on the bidding screens.  The Buyers’ Premium will be added onto the price of each items in your cart.    </p>


<p style={{fontWeight: 'bold'}}>CONSIDER DOING THIS</p>
<blockquote style={{paddingLeft: '30px'}}>
<p><span style={{paddingRight: '15px'}}><FontAwesomeIcon icon={faGavel} /></span><span style={{fontWeight: 'bold'}}>Place MAXBids</span>. Can't be online when an auction is closing? Place a MAXBid! Submit your maximum bid and our system will bid the lowest amount required for you to win up to your max.  A MAXbid wins in case of a tie bid, and the earliest of two MAXbids wins in case of tie between two MAXbids.</p>
<p><span style={{paddingRight: '15px'}}><FontAwesomeIcon icon={faGavel} /></span><span style={{fontWeight: 'bold'}}>Add items to your Watch List & get alerts</span>. Add a specific item to your Watch List by clicking on the heart next to the item bid amount.  You'll get email alerts when the item is accepting bids and before it closes so you won't miss it!  Always check your MYBIDLIVE to see if you are the highest bidder or have won an item.</p>
<p><span style={{paddingRight: '15px'}}><FontAwesomeIcon icon={faGavel} /></span><span style={{fontWeight: 'bold'}}>Create Saved Searches</span>. Be the first to know when we get new inventory. Save your searches and receive email alerts when matching items are added to our inventory.</p>
</blockquote>


<p style={{fontWeight: 'bold'}}>VIP MEMBER PAGE</p>

<p style={{fontWeight: 'bold'}}>Advantages</p>
<blockquote style={{paddingLeft: '50px', color: 'gray'}}>
<ol>
<li>Access to more information, such as BUY NOW prices and auction starting prices before the auction starts.</li>
<li>Blanket approval to bid with no limit at all our auctions, rather than going through the approval process for each one.</li>
<li>15% discount on Buyers’ Premiums</li>
</ol>
</blockquote>
<br />

*/}

<a href='#' onClick={() => setModalShow(true)}>Register to be a VIP Member here.</a>


<MyVerticallyCenteredModal
    show={modalShow}
    onHide={() => setModalShow(false)}
    
/>





        
          
        </Col>
        </Row> 
        </Container>
        </div>

        <div>
             <FPCmiscbar2 />
             <FPCfooter />
        </div>



    </Col></Row>
    </Container>
    )

}




export default Howtobuy;
