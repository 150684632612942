import React, {useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import {isLoggedUpdat} from '../actions';

import FPCmiscbar2 from './FPCmiscbar2.js'
import FPCfooter from './FPCfooter.js'


import {Button} from 'react-bootstrap';
import Form from 'react-bootstrap/Form'

import Navtop from './Navtop.js'
import { Link } from "react-router-dom";

import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

//import { baseUrl } from "./config";
import TermsTxt from './TermsTxt'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faExclamationTriangle, faTools } from '@fortawesome/free-solid-svg-icons'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope} from '@fortawesome/free-solid-svg-icons'
import { faEye } from "@fortawesome/free-solid-svg-icons";

import MyVerticallyCenteredModal from './MyVerticallyCenteredModal.js'
import ThankYouModal from './ThankYouModal.js'
import CreatePassw from './ModalCreatePass.js'
import Modalcpty from './Modalcpty.js'

import NavDummyCrumbs from './NavDummyCrumbs'



function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }
  

function UserLogin () {

    const [modalShow, setModalShow] = React.useState(false);
    const [modalShowty, setModalShowty] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const [modalCPShow, setModalCPShow] = React.useState(false);
    const [modalcpty, setModalcpty] = React.useState(false);

    const togglePasswordVisiblity = () => {
        console.log('toggle eye')
        setPasswordShown(passwordShown ? false : true);

    };

    const dispatch = useDispatch();
    const islogged = readCookie('islogged');

    const formik = useFormik({
        initialValues: {
          email: '',
          pword: '',
        },
        
        validationSchema: Yup.object({
          email: Yup.string()
            .email('Invalid email address')
            .required('Required'),

        }),
    
        onSubmit: values => {
            
        //console.log(JSON.stringify(values, null, 2))
    
            fetch('https://clubcamayan.com/rest/proja/api/ulogin', {
              method: 'POST',
              mode: 'cors',
              headers: {'Content-Type': 'application/json'},
              body: JSON.stringify(values, null, 2)
    
            }).then(res=> res.json())
              .then(res => {
                
                console.log(res)
                if (res.length){
                    var now = new Date();
                    now.setTime(now.getTime() + 2 * 3600 * 1000);
                    document.cookie = "uname="+res[0].name+"; expires=" + now.toUTCString() + "; path=/";
                    document.cookie = "bidno="+res[0].bidno+"; expires=" + now.toUTCString() + "; path=/";
                    document.cookie = "approved="+res[0].approved+"; expires=" + now.toUTCString() + "; path=/";
                    document.cookie = "email="+res[0].email+"; expires=" + now.toUTCString() + "; path=/";
                    document.cookie = "userid="+res[0].userid+"; expires=" + now.toUTCString() + "; path=/";
                    document.cookie = "bidlimit="+res[0].bidlimit+"; expires=" + now.toUTCString() + "; path=/";
                    document.cookie = "islogged="+res[0].userid+"; expires=" + now.toUTCString() + "; path=/";
                    window.location.reload();
                }else{
                    var elem = document.querySelector('#badlogin');
                    
                    elem.innerHTML = 'Bad Login.';

                    
                }    

              
              }
              
              );
          
    
          //props.onHide();
          //props.onNext();
          //setModalShowty(true);
    
        },
    
    
      });
    
      

    return (

    <Container fluid>
        
    <Navtop /> 


      <Row><Col sm={12} md={12} style={{padding: '0px', color: 'black',borderTop: '1px solid #cccccc'}}>

        <div style={{maxWidth: '1140px', border: '0px solid black'}} className="container-fluid d-flex justify-content-center" >
        <Container>
            
        <Row><Col xs={12} sm={12} md={12}>
            <NavDummyCrumbs />
        </Col></Row>

        <Row><Col xs={12} sm={12} md={12}>
            <br/>
            <Container style={{backgroundColor: '#f8f9fa', border: '1px solid #dee2e6'}}>
                <Row>


                    {/* ==================================================================================================================================== */}
                    <Col xs={12} sm={12} md={6} style={{border: '0px solid #dedede', padding: '30px'}}>

                      {islogged===null?
                      (<div>
                      <span style={{color: '#ffba08', fontWeight: 'bold'}}>Sign in</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span id='badlogin' style={{color: "red"}}></span> <br /><br />
                      <Form onSubmit={formik.handleSubmit} id="loginform">
                          <Form.Group controlId="email">
                              <Form.Label htmlFor="email">Email Address</Form.Label>
                              <Form.Control id="email" type="email" {...formik.getFieldProps('email')} size='sm' />
                              {formik.touched.email && formik.errors.email ? (<div style={{fontSize: '11px', color: 'red'}}>{formik.errors.email}</div>) : null}
                          </Form.Group>

                          <Form.Group controlId="pword">
                              <Form.Label htmlFor="pword">Password</Form.Label>
                              <InputGroup size='sm'>
                              
                              <Form.Control id="pword" type={passwordShown ? "text" : "password"} {...formik.getFieldProps('pword')} size='sm' />
                              <InputGroup.Append><InputGroup.Text><FontAwesomeIcon icon={faEye} onClick={togglePasswordVisiblity} /></InputGroup.Text></InputGroup.Append>
                              {/* {formik.touched.phone && formik.errors.phone ? (<div style={{fontSize: '11px', color: 'red'}}>{formik.errors.phone}</div>) : null}   */}
                              </InputGroup>
                          </Form.Group>
          
                          <Button size='sm' variant="primary" type="submit" style={{marginBottom: '0px'}}>&nbsp;&nbsp;&nbsp;&nbsp;Sign In&nbsp;&nbsp;&nbsp;&nbsp;</Button>
                          <span style={{fontSize: '12px'}}>&nbsp;&nbsp;&nbsp;
                          <span style={{color: '#0077b6', cursor: 'pointer'}} onClick={() => setModalCPShow(true)}>Forgot your password?</span> &nbsp;&nbsp;&nbsp;
                          
                          </span>
                      </Form>
                      </div>)
                      :

                      <div><h4 style={{color: 'green'}}>You are now logged in.</h4></div>

                      }


                      <span style={{fontSize: '12px'}}>Usage subject to <Link to={`/terms`}>Terms & Conditions.</Link> </span>

                    </Col>
                    
                    {/* ==================================================================================================================================== */}

                    <Col xs={12} sm={12} md={6} style={{border: '0px solid #dedede', paddingLeft: '150px', paddingTop: '30px'}}>
                      <span style={{color: '#ffba08', fontWeight: 'bold'}}>Not a member yet?<br /><br/></span>
                              
                      <b>Benefits of being a member:</b> <br /><br />
                      <ul style={{listStyle: 'none', fontSize: '13px'}}>
                        <li><FontAwesomeIcon icon={faCheck} style={{color: 'green'}} />&nbsp;&nbsp;P300 voucher that can be applied to any purchase of merchandise items in any of our gift shops</li>
                        <li><FontAwesomeIcon icon={faCheck} style={{color: 'green'}} />&nbsp;&nbsp;Add Items to your Watch List using MYDEALS</li>
                        <li><FontAwesomeIcon icon={faCheck} style={{color: 'green'}} />&nbsp;&nbsp;View recently sold deals with price data</li>
                        <li><FontAwesomeIcon icon={faCheck} style={{color: 'green'}} />&nbsp;&nbsp;Make purchases at rock bottom prices!</li>
                      </ul>
          
                      <span style={{fontSize: '13px', fontWeight:'bold'}}> </span>
                      <br /><br />
                      <div>
                        <Button size='sm' onClick={() => setModalShow(true)}>Register for Free</Button>
                        <MyVerticallyCenteredModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            onNext={() => setModalShowty(true)}
                        />
                        <ThankYouModal
                            show={modalShowty}
                            onHide={() => setModalShowty(false)}
                        />
                        <CreatePassw
                            show={modalCPShow}
                            onHide={() => setModalCPShow(false)}
                            onNext={() => setModalcpty(true)}
                        />
                        <Modalcpty
                            show={modalcpty}
                            onHide={() => setModalcpty(false)}
                        />
                      </div>
                    
                    </Col>
                    {/* ==================================================================================================================================== */}

                </Row>
            </Container>
        </Col></Row>





        <Row><Col xs={12} sm={12} md={12}>
        <br/><br/>
          <TermsTxt />
        </Col>
        </Row> 
        </Container>
        </div>

        <div>
             <FPCmiscbar2 />
             <FPCfooter />
        </div>



    </Col></Row>
    </Container>
    )

}




export default UserLogin;
